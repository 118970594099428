// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
import "channels"
import "jquery"

import 'moment'
import '../stylesheets/application.scss'
import './components/autosize_text'
import './components/offline_purchase'
import './components/offline_booking'
import './components/offline_membership'
import './components/offline_anti_cafe'
import './components/online_inquiry'
import './components/block_dates'
import './components/checkout.js.erb'
import './components/registration.js.erb'
import './components/confirm-payment.js.erb'
import './components/input_file'
import './components/inventory_online'
import './components/update-payment.js.erb'
import './components/countdown'
import './components/slick'
import './components/table'
import './components/homepage'
import './components/validate'
import './components/portal_booking.js.erb'
import './components/membership_online.js.erb'
import './components/date_time_picker'
import './components/accordion_menu'
import './administrate/accordion-store'
import './administrate/simple-modal'
import './administrate/simple-alert'
import './components/simple-alert-override'
import './administrate/rails-delegate-handler'

require.context('../images', true)

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
require("trix")
require("@rails/actiontext")