class SimpleAlert {
    constructor(){}

    set_ui(elem){
        $('body').addClass('alert-open')
        ui = {
            alert: elem
        }
        elem.find('[data-no]').on('click', ()=>{ this.close(ui) })
    }

    ui() {
        let alert = $('<div />', {id: 'simple-alert'})
        let inner = $('<div />', {class: 'alert-inner'})
        let title = $('<p />')
        let action = $('<div />', {class: 'alert-action'})
        inner.append(title)
        inner.append(action)
        alert.append(inner)

        return {alert, title, action}
    }

    alert(message, callback) {
        this.clear()
        let ui = this.ui()
        ui.title.text(message)
        
        
        // action
        let ok = $('<button>', {alert: 'btn btn-primary'})
                    .text('OK')
                    .on('click', ()=>{ this.close(ui) })
                    .appendTo(ui.action)

        $(document).on('keypress', (e) => {
            if($('#simple-alert').length){
                if(e.which == 13 || e.which == 27) {
                    this.close(ui)
                }
            }
        });

        this.display(ui)
        $('body').addClass('alert-open')
        callback(ok)
    }

    confirm(message, confirmText = 'Yes', cancelText = 'No', callback) {
        this.clear()
        let ui = this.ui()
        ui.title.html(message)

        // action
        let yes = $('<button>', {class: 'button mr-4'})
                    .text(confirmText)
                    .on('click', ()=>{ this.close(ui) })
                    .appendTo(ui.action);

        let no = $('<button>', {class: 'button--alt'})
                    .text(cancelText)
                    .on('click', ()=>{ this.close(ui) })
                    .appendTo(ui.action);

        $(document).on('keyup', (e) => {
            if($('#simple-alert').length){
                if(e.which == 27) {
                    this.close(ui)
                }
            }
        });

        this.display(ui)
        $('body').addClass('alert-open')
        callback(yes, no)
    }

    display(ui){
        $('body').append(ui.alert)
    }

    clear(){
        $('#simple-alert').remove()
    }

    close(ui){
        ui.alert.remove()
        $('body').removeClass('alert-open')
    }
}

SimpleAlert.alert = function(message){
    return new Promise(resolve => {
        let $dialog = new SimpleAlert();
        $dialog.alert(message, function($ok){
            $ok.click(function(event) {
                resolve(true)
            })
        })
    })
}

SimpleAlert.confirm = function(message, confirmText = 'Yes', cancelText = 'No'){
    return new Promise(resolve => {

        let $dialog = new SimpleAlert();
        $dialog.confirm(message, confirmText, cancelText, function($yes, $no){
            $yes.click(function(event) {
                resolve(true)
            })
            $no.click(function(event) {
                resolve(false)
            })
        })
    })
}


window.confirm = SimpleAlert.confirm
window.alert = SimpleAlert.alert

window.SimpleAlert = SimpleAlert;
