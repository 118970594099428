import {getMeetingRoomPrice} from "../services/bookings";
import {moneyFormat} from "../utils/money";
import { post } from './api'
import { CORPORATE_WITHOLDING_TAX , getWithPaymentTypeValue, handleQrCode, handleEnableCreditCardMethodForWht, setWithHoldingtax, resetForm } from './checkout.js.erb'
import { isMobileSize } from "../utils/common";




const state = {
  step: 1
}


$(document).on('turbolinks:load', function () {

  const bookingContainer = $('.portal-booking-container')
  const form = bookingContainer.find('form')
  const agreeCheckBox = form.find('#create_booking_agreeTerm')

  if( bookingContainer.length <= 0 ) return

  // Add Custom rule for select time when invalid time
  $.validator.addMethod("invalidtime", function(value, element) {
    return !$('.select2-selection').hasClass('invalid-time');
  }, "Invalid Time");

  // Add custom rules for validate phone number
  $.validator.addMethod("formatPhone", function(value, element) {
    return new RegExp('^\\(?([0-9]{3})\\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$').test(value)
  }, "Phone is invalid");

  agreeCheckBox.on('change', function () {
    handleChargeButton()
  })
  // trigger every input for change order summary
  $('input#booking_date, select#booking_time, input#hour').change(function () {
    setOrderSummary()
  })

  // set time select to null when date change
  $('input#booking_date').change(function () {
    const timeInput = form.find('#booking_time')
    timeInput.val(null).trigger('change')
    setOrderSummary()
  })

  // handle use Credit checkbox
  $('input#useCredit').change(function () {
    if (this.checked) {
      $('input[name=payment-method]').attr('disabled',true)
      hideVoucher()
    } else {
      $('input[name=payment-method]').attr('disabled',false)
      showVoucher()
    }
    setOrderSummary()
  })

  // handle credit type select
  $('select#credit_type').change(function () {
    setOrderSummary()
  })


  // handle apply code
  $('button.vocuher-button').click(function (e) {
    e.preventDefault()
    setOrderSummary()

  })

  $('#submit-omise').click(function (e) {
      e.preventDefault()
      $('#booking-form').removeData("validator").removeData("unobtrusiveValidation"); //remove the form validation => refresh form.valid()
      $.validator.unobtrusive.parse($('#booking-form'));//add the form validation
      submitForm()
  })

  // handle remove voucher code
  handleRemoveCode()

  // handle show qr code when selected transfer method
  handleQrCode()

  //handle selected request withholding tax
  handleRequestWithHoldingTax()

  //handle booking form when mobile size
  handleBookingForm()

    backStep()
    // set booking order summary (wait 100ms for get booking value)
  function setOrderSummary() {
    setTimeout( function () {
        const hourInput = form.find('#hour')
        const dateInput = form.find('#booking_date')
        const timeInput = form.find('#booking_time')
        const useCreditChecbox = form.find('#useCredit')
        const creditTypeInput = form.find('#credit_type')
        const hour = hourInput.val()
        const meetingRoomId = $('#booking_meeting_room_id').val()
        const date = dateInput.val()
        const time = timeInput.val()
        const startDateInput = $('#booking_start_date')
        const hourText = $('#hour-booking')
        const priceText = $('.price-amount')
        const use_credit = useCreditChecbox.is(':checked')
        const creditType = creditTypeInput.val()
        const code = $('#code').length ? $('#code').val().toUpperCase() : ''
        const requestWht = isRequestWht()

        if (meetingRoomId && hour && date && time ) {
            getMeetingRoomPrice(meetingRoomId, hour, date, time, use_credit, creditType, code, requestWht).then(function (data) {

              if (data.order_summary) {


                // Set Data section ====================================
                const orderSummary = data.order_summary
                const capacityInput = $('#capacity')
                startDateInput.val(new Date(dateInput.val() + ' ' + timeInput.val()));
                $('#final_price').attr('data-price', orderSummary.total)

                hourText.html(`( ${hour} ${hour > 1 ? 'Hours' : 'Hour'} )`)
                priceText.html(moneyFormat(data.price))
                priceText.html(moneyFormat(data.price))
                  capacityInput.attr('max', data.capacity)


                $('#sub_total').html(moneyFormat(orderSummary.sub_total))
                $('#vat').html(moneyFormat(orderSummary.vat))
                $('#final_price').html(moneyFormat(orderSummary.total))
                $('#amount-credits').html(orderSummary.amount_credit)
                $('#available-credits').html(orderSummary.available_credit)

                hideInvalidCode()
                showCreditContainer()
                if(orderSummary.total < 20 && !use_credit) {
                  $('input#creditcard').prop('disabled', true)
                  $('input#transfer').prop('checked', true)
                }

                if(orderSummary.total === 0) {
                  // If use credit then checkbox request withholding is disabled and unchecked
                    $('#requestWht').prop('checked', false)
                    $('#requestWht').attr('disabled', true)
                }else {
                  $('#requestWht').attr('disabled', false)
                  if(!requestWht) {
                    $('input#creditcard').prop('disabled', false)
                  }
                }
                if(orderSummary.voucher_discount > 0 ) {
                  $('#discount').html(`- ${moneyFormat(orderSummary.voucher_discount)}`)
                  if(!use_credit) {
                    hideUseCredit()
                  }

                showRemoveCodeButton()
                } else {
                  if(orderSummary.voucher_invalid) {
                    setInvalidCode()
                    $('#discount').html(`${moneyFormat(0)}`)
                  } else {
                    $('#discount').html(`${moneyFormat(0)}`)
                  }
                }

                setWithHoldingtax(orderSummary.wht)
                //========================================================

                hideInvalidTime()
                handleChargeButton()



              } else {
                setInvalidTime()
                handleChargeButton()
              }
            })
        } else {
          clearOrderSummary()
        }
    },100)
  }

  // Clear order summary section
  function clearOrderSummary() {
    const hourText = $('#hour-booking')
    const priceText = $('.price-amount')
    $('#sub_total').html(moneyFormat(0))
    $('#vat').html(moneyFormat(0))
    $('#final_price').html(moneyFormat(0))
    hourText.html('')
    priceText.html(moneyFormat(0))
  }


// remove  invalid time for check validation
  function setInvalidTime() {
    $('.select2-selection').addClass('invalid-time')
    clearOrderSummary()
  }

  // set time select to invalid time for check validation
  function hideInvalidTime() {
    $('.select2-selection').removeClass('invalid-time')
  }


  // handle agree term to disabled or enabled submit button
  function handleChargeButton() {
    const buttonSubmit = $('#submit-omise')
    const agreeCheckBox = form.find('#create_booking_agreeTerm')

    if(isMobileSize() && state.step == 1) return
    if(agreeCheckBox.is(":checked")) {
      buttonSubmit.removeClass('disabled')
      buttonSubmit.removeAttr('disabled')
    } else {
      buttonSubmit.addClass('disabled')
      buttonSubmit.attr('disabled', 'disabled')
    }

  }

  // handle api
  function sendData(data) {
    $('#submit-omise').attr('disabled', true)
    const url = form.attr('action')
    post(url, JSON.stringify(data), true)

  }


  // Handle data send to backend and handle validation
  function submitForm() {
    var bookingForm = $('#booking-form')
    const validateOption = getValidateOption()
    bookingForm.validate({
      errorClass: 'is-invalid',
      rules: validateOption.rules,
      messages: validateOption.messages,
      errorPlacement: function(error, element) {
        const errorContainer = element.parent().find('.invalid-feedback');
        errorContainer.html('')
        errorContainer.html(error)
      },
      highlight: function(element, errorClass, validClass) {
        $(element).addClass(errorClass)
        scrollToForm()
      },
      unhighlight: function (element, errorClass, validClass) {
        $(element).removeClass(errorClass)
      }
    })

    if(!bookingForm.valid()) return false


    if(isMobileSize() && state.step === 1) {
      state.step = 2
      handleBookingForm()
    } else {
      submitFormData()
    }
  }

  handlePaymentType()

  handleEnableCreditCardMethodForWht()

  function submitFormData() {

    const paymentMethod = $("input[name='payment-method']:checked").val();
    const useCredit = $('#useCredit').is(':checked')
    const code = $('#code').val().toUpperCase()
    const data = {
      firstname: $('#first_name').val(),
      lastname: $('#last_name').val(),
      email: $('#email').val(),
      contact_number: $('#phone').val(),
      noted: $('#note').val(),
      facebook_url: $('#facebook_url').val(),
      product_type: 'Booking',
      start_date: $('#booking_date').val(),
      time: $('#booking_time').val(),
      hours: $('#hour').val(),
      capacity: $('#capacity').val(),
      payment_method: useCredit ? 'Credit' : paymentMethod,
      meeting_room_id: $('#booking_meeting_room_id').val(),
      use_credit: useCredit,
      credit_type: $('#credit_type').val(),
      code,
      customer_type: getWithPaymentTypeValue()
    }
    // merge corporate info to data when payment type is corporate

    if(data.customer_type == CORPORATE_WITHOLDING_TAX) {
      Object.assign(data, { company_name: $('#billingName').val(), company_address: $('#billingAddress').val(), company_tax_number: $('#taxId').val(), company_branch: $('#companyBranch').val(), request_for_wht: isRequestWht()})
    }

    if(data.payment_method === "Bank Transfer" || data.payment_method == 'Credit') {
      //   form.submit()
      sendData({ data })
    }else {

      if('OmiseCard' in window) {
        const publicKey = $('#creditInput').data('omisePublicKey')

        window.OmiseCard.configure({
          publicKey: publicKey,
          frameLabel: 'HUBBA',
          image: `${window.location.origin}/HUBBA-Logo-square.jpg`
        });

        setTimeout(function () {
          const amount = parseFloat($('#final_price').attr('data-price')) * 100
          window.OmiseCard.open({
            amount,
            submitFormTarget: '#booking-form',
            onCreateTokenSuccess: function (nonce) {
              sendData({
                token: nonce,
                amount,
                currency: "thb",
                data: data
              })
              /* Handler on token or source creation.  Use this to submit form or send ajax request to server */
            }
          })
        }, 1000)
      }
    }
  }

  function handleRemoveCode() {
    $('#remove-code').on('click', function () {
      clearVoucherCode()
      showApplyCodeButton()
    })
  }

  function hideUseCredit() {
    const useCreditContainer = $('#use-credit')

    useCreditContainer.hide()
  }

  function showCreditContainer() {
    const useCreditContainer = $('#use-credit')

    useCreditContainer.show()
  }

  function clearVoucherCode() {
      $('input#code').val('')
  }

  function showRemoveCodeButton() {
    $('#apply-code').hide()
    $('#remove-code').show()
  }

  function showApplyCodeButton() {
    $('#apply-code').show()
    $('#remove-code').hide()
  }

  function setInvalidCode() {
    $('#code_error').html('Invalid code').show()
  }

  function hideInvalidCode() {
    $('#code_error').hide()
  }

  function hideVoucher() {
    $('#voucher').hide()
  }

  function showVoucher() {
    $('#voucher').show()
  }

  // handle select payment type

  function handlePaymentType() {
    showOrHideCorporateInfo()
    setOrderSummary()

    $('#customer_type').on('change', function(evt) {
      showOrHideCorporateInfo()
      setOrderSummary()
    })
  }

  // handle checkbox request withholding tax

  function handleRequestWithHoldingTax() {
    const requestWhtCheckbox = $('input#create_booking_requestWht')

    if(!requestWhtCheckbox.length) return

    handleEnableCreditCardMethodForWht()

    requestWhtCheckbox.change(function() {

      // set order summary api
      setOrderSummary()
      handleEnableCreditCardMethodForWht()
    })
  }

  function isRequestWht() {
    return $("input#create_booking_requestWht").is(':checked')
  }

})
export function scrollToForm() {
    const divForScroll = state.step == 1 ? $('#meeting-room-form') : $('#billing-info-form')
    $('html, body').animate({
        scrollTop: divForScroll.offset().top - 100
    }, 'fast');
}
export function handleBookingForm() {

    if(!isMobileSize()) return

    const bookingForm = $('#meeting-room-form')
    const billingInfoForm = $('#billing-info-form')
    const agreeTerm = $('#agree-term-section');
    const paymentMethod = $('#payment-method-section')
    const submitButton = $('#submit-omise')
    const backButton = $('#back-step')
    const detailSection = $('#detail')

    if(state.step == 1) {
        billingInfoForm.invisible()
        bookingForm.visible()
        agreeTerm.invisible();
        paymentMethod.invisible()
        submitButton.val('Go to Checkout')
        submitButton.attr('disabled', false)
        detailSection.visible()
        backButton.hide()
    } else {
        bookingForm.invisible()
        billingInfoForm.visible()
        paymentMethod.visible()
        agreeTerm.visible();
        submitButton.val('Proceed to check out')
        submitButton.attr('disabled', true)
        backButton.show()
        detailSection.invisible()
        scrollToForm()
    }
}


function getValidateOption() {
    const isValidate = (!isMobileSize() && state.step == 1) || (isMobileSize() && state.step == 2)
  const validateOption = {
    rules: {
      'create_booking[booking_date]': {
          required: true
      },
      'create_booking[booking_time]': {
          required: true
      },
      'create_booking[hour]': {
          required: true
      },
      'create_booking[type]': {
        required: isValidate
      },
      'create_booking[first_name]': {
        required: isValidate
      },
      'create_booking[last_name]': {
          required: isValidate
      },
      'create_booking[email]': {
          required: isValidate
      },
      'create_booking[phone]': {
          required: isValidate
      },
      'create_booking[billingName]': {
          required: isValidate
      },
      'create_booking[companyBranch]': {
          required: isValidate
      },
      'create_booking[billingAddress]': {
          required: isValidate
      },
      'create_booking[taxId]': {
          required: isValidate
      },
      time: {
        invalidtime: true
      },
      phone: {
        formatPhone: true
      }
    },
    messages: {
      'create_booking[booking_time]': {
        required: getDataRequiredMessage('booking_time')
      },
      'create_booking[hour]': {
        required: getDataRequiredMessage('hour')
      },
      'create_booking[type]': {
        required: getDataRequiredMessage('customer_type')
      },
      'create_booking[first_name]': {
        required: getDataRequiredMessage('first_name')
      },
      'create_booking[last_name]': {
        required: getDataRequiredMessage('last_name')
      },
      'create_booking[email]': {
        required: getDataRequiredMessage('email')
      },
      'create_booking[phone]': {
        required: getDataRequiredMessage('phone'),
        formatPhone: getDataInvalidMessage('phone')
      },
      'create_booking[billingName]': {
          required: getDataRequiredMessage('billingName')
      },
      'create_booking[companyBranch]': {
          required: getDataRequiredMessage('companyBranch')
      },
      'create_booking[billingAddress]': {
          required: getDataRequiredMessage('billingAddress')
      },
      'create_booking[taxId]': {
          required: getDataRequiredMessage('taxId')
      },
    }
  }
  return validateOption
}

export function getDataRequiredMessage(id) {
  const label = $(`input[id=${id}] ,select[id=${id}`).closest('.field-unit').find('label').html();

  if(!label) return "Field can't be blank"
  const firstLetter = label.charAt(0).toUpperCase();
  const remainLetter = label.toLowerCase().slice(1)
  return `${firstLetter + remainLetter} can't be blank`
}

export function getDataInvalidMessage(name) {
  return $(`input[name=${name}]`).data('invalidMessage')
}

export function showOrHideCorporateInfo() {
    if(getWithPaymentTypeValue() == CORPORATE_WITHOLDING_TAX) {
        $('#corporate-info').show()
    }else {
        $('#corporate-info').hide()

        // reset validate corporate form and uncheck withholding tax when select  "personal" and re set order summary
        $('#corporate-info input').each(function () {
            resetForm($(this), $(this).find('message') )
        })
    }
}

export function backStep() {
    const backButton = $('#back-step')

    backButton.click(function () {
        state.step = 1
        handleBookingForm()
    })
}
