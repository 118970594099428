$(document).on('turbolinks:load', function () {
  const slick =  $('.slick')
  const numberSlide = slick.data('slideToShow')

  if(slick.length > 0) {
    slick.slick({
      dots: false,
      arrows: true,
      slidesToShow: numberSlide || 1,
      responsive: [{
        breakpoint: 1700,
        settings: {
          slidesToShow: numberSlide || 1,
          slidesToScroll: numberSlide || 1,
        },
      },{
        breakpoint: 1024,
        settings: {
          slidesToShow: numberSlide || 1,
          slidesToScroll: numberSlide || 1,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }]
    })
  }
})