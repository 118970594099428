import {isMobileSize} from "../utils/common";
import {getInventoryPrice} from "../services/inventories";
import {moneyFormat} from "../utils/money";
import {
  CORPORATE_WITHOLDING_TAX,
  getWithPaymentTypeValue,
  handleQrCode,
  setWithHoldingtax
} from "./checkout.js.erb";
import {getDataRequiredMessage, showOrHideCorporateInfo, getDataInvalidMessage} from "./portal_booking.js.erb";
import {post} from "./api";
const inventoryContainerClass = '.inventory-container'
const agreeTermId = '#inventory_agreeTerm'
const submitButtonId = '#submit-omise'
const productForm = '#product-form'
const priceTextId = '.price-amount'
const subTotalId = '#sub_total'
const vatId = '#vat'
const finalPriceId = '#final_price'
const errorVoucherInvalidId = '#code_error'
const discountPriceId = '#discount'
const applyCodeId = '#apply-code'
const removeCodeId = '#remove-code'
const voucherSubmitButton = '.vocuher-button'

// Form
const inventoryInputId = '#inventory_id'
const quantityInputId = '#quantity'
const requestWhtId = '#inventory_requestWht'
const inputCreditCardId = 'input#creditcard'
const inputBankTransferId = 'input#transfer'
const voucherCodeInputId = 'input#code'
const checkboxUseCreditId = 'input#useCredit'


const state = {
  step: 1
}


$(document).on('turbolinks:load', function () {
  const inventoryContainer = $(inventoryContainerClass)
  const agreeCheckBox = $(agreeTermId)
  const inventoryForm = $(productForm)

  if( inventoryContainer.length <= 0 ) return

  handleRequestWithHoldingTax()
  handleQrCode()
  handlePaymentType()
  handleBookingForm()

  agreeCheckBox.on('change', function () {
    handleChargeButton()
  })

  $(quantityInputId).change(function () {
    setOrderSummary()
  })

  $(voucherSubmitButton).click(function (e) {
    e.preventDefault()
    setOrderSummary()

  })

  $(checkboxUseCreditId).change(function () {
    if (this.checked) {
      $('input[name=payment-method]').attr('disabled',true)
      // hideVoucher()
    } else {
      $('input[name=payment-method]').attr('disabled',false)
      // showVoucher()
    }
    setOrderSummary()
  })


  // handle agree term to disabled or enabled submit button
  function handleChargeButton() {
    const buttonSubmit = $(submitButtonId)
    if(isMobileSize() && state.step == 1) return
    if(agreeCheckBox.is(":checked")) {
      buttonSubmit.removeClass('disabled')
      buttonSubmit.removeAttr('disabled')
    } else {
      buttonSubmit.addClass('disabled')
      buttonSubmit.attr('disabled', 'disabled')
    }

  }
  $(submitButtonId).click(function (e) {
    e.preventDefault()
    inventoryForm.removeData("validator").removeData("unobtrusiveValidation"); //remove the form validation => refresh form.valid()
    $.validator.unobtrusive.parse(inventoryForm);//add the form validation
    submitForm()
  })

  function setOrderSummary() {
    setTimeout( function () {

      const inventoryId = $(inventoryInputId).val()
      const quantity = $(quantityInputId).val()
      const code = $('#code').length ? $('#code').val().toUpperCase() : ''
      const requestWht = isRequestWht()
      const useCredit = $(checkboxUseCreditId).val() == 'on'
      if (inventoryId && quantity) {
        getInventoryPrice(inventoryId, quantity, code, requestWht, useCredit).then(function (data) {

          if (data.order_summary) {

            // Set Data section ====================================
            const orderSummary = data.order_summary

            $(subTotalId).html(moneyFormat(orderSummary.sub_total))
            $(vatId).html(moneyFormat(orderSummary.vat))
            $(finalPriceId).html(moneyFormat(orderSummary.total))
            $(finalPriceId).attr('data-price', orderSummary.total)

            hideInvalidCode()
            if(orderSummary.total < 20 ) {
              $(inputCreditCardId).prop('disabled', true)
              $(inputBankTransferId).prop('checked', true)
            }
            //
            if(orderSummary.total === 0) {
              // If use credit then checkbox request withholding is disabled and unchecked
              $(requestWhtId).prop('checked', false)
              $(requestWhtId).attr('disabled', true)
            }else {
              $(requestWhtId).attr('disabled', false)
              if(!requestWht) {
                $(inputCreditCardId).prop('disabled', false)
              }
            }

            if(orderSummary.voucher_discount > 0 ) {
              $(discountPriceId).html(`- ${moneyFormat(orderSummary.voucher_discount)}`)
            //
              showRemoveCodeButton()
            } else {
              if(orderSummary.voucher_invalid) {
                setInvalidCode()
                $(discountPriceId).html(`${moneyFormat(0)}`)
              } else {
                $(discountPriceId).html(`${moneyFormat(0)}`)
              }
            }

            setWithHoldingtax(orderSummary.wht)
            //========================================================

            handleChargeButton()



          } else {
            handleChargeButton()
          }
        })
      } else {
        clearOrderSummary()
      }
    },100)
  }

  function isRequestWht() {
    return $(requestWhtId).is(':checked')
  }

  function handleRequestWithHoldingTax() {
    const requestWhtCheckbox = $(requestWhtId)

    if(!requestWhtCheckbox.length) return

    // handleEnableCreditCardMethodForWht()

    requestWhtCheckbox.change(function() {
      // set order summary api
      setOrderSummary()
      // handleEnableCreditCardMethodForWht()
    })
  }


  // Clear order summary section
  function clearOrderSummary() {
    const priceText = $(priceTextId)
    $(subTotalId).html(moneyFormat(0))
    $(vatId).html(moneyFormat(0))
    $(finalPriceId).html(moneyFormat(0))
    priceText.html(moneyFormat(0))
  }

  function handleRemoveCode() {
    $(removeCodeId).on('click', function () {
      clearVoucherCode()
      showApplyCodeButton()
    })
  }

  function clearVoucherCode() {
    $(voucherCodeInputId).val('')
  }

  function showRemoveCodeButton() {
    $(applyCodeId).hide()
    $(removeCodeId).show()
  }

  function showApplyCodeButton() {
    $(applyCodeId).show()
    $(removeCodeId).hide()
  }

  function setInvalidCode() {
    $(errorVoucherInvalidId).html('Invalid code').show()
  }

  function hideInvalidCode() {
    $(errorVoucherInvalidId).hide()
  }

  // handle select payment type

  function handlePaymentType() {
    showOrHideCorporateInfo()
    $('#customer_type').on('change', function(evt) {
      showOrHideCorporateInfo()
    })
  }

  function getValidateOption() {
    const isValidate = (!isMobileSize() && state.step == 1) || (isMobileSize() && state.step == 2)
    const validateOption = {
      rules: {
        'inventory[quantity]': {
          required: true
        },
        'inventory[type]': {
          required: isValidate
        },
        'inventory[first_name]': {
          required: isValidate
        },
        'inventory[last_name]': {
          required: isValidate
        },
        'inventory[email]': {
          required: isValidate
        },
        'inventory[phone]': {
          required: isValidate
        },
        'inventory[billingName]': {
          required: isValidate
        },
        'inventory[companyBranch]': {
          required: isValidate
        },
        'inventory[billingAddress]': {
          required: isValidate
        },
        'inventory[taxId]': {
          required: isValidate
        },

      },
      messages: {
        'inventory[quantity]': {
          required: getDataRequiredMessage('quantity')
        },
        'inventory[hour]': {
          required: getDataRequiredMessage('hour')
        },
        'inventory[type]': {
          required: getDataRequiredMessage('customer_type')
        },
        'inventory[first_name]': {
          required: getDataRequiredMessage('first_name')
        },
        'inventory[last_name]': {
          required: getDataRequiredMessage('last_name')
        },
        'inventory[email]': {
          required: getDataRequiredMessage('email')
        },
        'inventory[phone]': {
          required: getDataRequiredMessage('phone'),
          formatPhone: getDataInvalidMessage('phone')
        },
        'inventory[billingName]': {
          required: getDataRequiredMessage('billingName')
        },
        'inventory[companyBranch]': {
          required: getDataRequiredMessage('companyBranch')
        },
        'inventory[billingAddress]': {
          required: getDataRequiredMessage('billingAddress')
        },
        'inventory[taxId]': {
          required: getDataRequiredMessage('taxId')
        },
      }
    }
    return validateOption
  }

  function submitForm() {
    const validateOption = getValidateOption()
    inventoryForm.validate({
      errorClass: 'is-invalid',
      rules: validateOption.rules,
      messages: validateOption.messages,
      errorPlacement: function(error, element) {
        const errorContainer = element.parent().find('.invalid-feedback')
        errorContainer.html(error)
      },
      highlight: function(element, errorClass, validClass) {
        $(element).addClass(errorClass)
        scrollToForm()
      },
      unhighlight: function (element, errorClass, validClass) {
        $(element).removeClass(errorClass)
      }
    })

    if(!inventoryForm.valid()) return false


    if(isMobileSize() && state.step === 1) {
      state.step = 2
      handleBookingForm()
    } else {
      submitFormData()
    }
  }

  function scrollToForm() {
    const divForScroll = state.step == 1 ? $('#inventory-form') : $('#billing-info-form')
    $('html, body').animate({
      scrollTop: divForScroll.offset().top - 100
    }, 'fast');
  }


  function handleBookingForm() {

    if(!isMobileSize()) return

    const billingInfoForm = $('#billing-info-form')
    const agreeTerm = $('#agree-term-section');
    const paymentMethod = $('#payment-method-section')
    const submitButton = $(submitButtonId)
    const backButton = $('#back-step')
    const detailSection = $('#detail')
    const inventoryForm = $('#inventory-form')

    if(state.step == 1) {
      billingInfoForm.invisible()
      inventoryForm.visible()
      agreeTerm.invisible();
      paymentMethod.invisible()
      submitButton.val('Go to Checkout')
      submitButton.attr('disabled', false)
      detailSection.visible()
      backButton.hide()
    } else {
      inventoryForm.invisible()
      billingInfoForm.visible()
      paymentMethod.visible()
      agreeTerm.visible();
      submitButton.val('Proceed to check out')
      submitButton.attr('disabled', true)
      detailSection.invisible()
      backButton.show()
      scrollToForm()
    }
  }

  function backStep() {
    const backButton = $('#back-step')

    backButton.click(function () {
      state.step = 1
      handleBookingForm()
    })
  }


  function submitFormData() {

    const paymentMethod = $("input[name='payment-method']:checked").val();
    const useCredit = $('#useCredit').is(':checked')
    const code = $('#code').val().toUpperCase()
    const data = {
      firstname: $('#first_name').val(),
      lastname: $('#last_name').val(),
      email: $('#email').val(),
      contact_number: $('#phone').val(),
      noted: '',
      product_type: 'Purchase Item',
      quantity: $(quantityInputId).val(),
      payment_method: useCredit ? 'Credit' : paymentMethod,
      inventory_id: $(inventoryInputId).val(),
      use_credit: useCredit,
      credit_type: $('#credit_type').val(),
      code,
      customer_type: getWithPaymentTypeValue()
    }
    // merge corporate info to data when payment type is corporate
    console.log({ data })
    if(data.customer_type == CORPORATE_WITHOLDING_TAX) {
      Object.assign(data, { company_name: $('#billingName').val(), company_address: $('#billingAddress').val(), company_tax_number: $('#taxId').val(), company_branch: $('#companyBranch').val(), request_for_wht: isRequestWht()})
    }

    if(data.payment_method === "Bank Transfer" || data.payment_method == 'Credit') {
      //   form.submit()
      sendData({ data })
    }else {

      if('OmiseCard' in window) {
        const publicKey = $('#creditInput').data('omisePublicKey')

        window.OmiseCard.configure({
          publicKey: publicKey,
          frameLabel: 'HUBBA',
          image: `${window.location.origin}/HUBBA-Logo-square.jpg`
        });

        setTimeout(function () {
          const amount = parseFloat($('#final_price').attr('data-price')) * 100
          console.log(amount)
          window.OmiseCard.open({
            amount,
            submitFormTarget: '#product-form',
            onCreateTokenSuccess: function (nonce) {
              sendData({
                token: nonce,
                amount,
                currency: "thb",
                data: data
              })
              /* Handler on token or source creation.  Use this to submit form or send ajax request to server */
            }
          })
        }, 1000)
      }
    }
  }


  function sendData(data) {
    $(submitButtonId).attr('disabled', true)
    const url = inventoryForm.attr('action')
    post(url, JSON.stringify(data), true)
  }



});