$(document).on('turbolinks:load', function () {
    $('.accordion-menus > label').on('click', function(){
        let menu = JSON.parse(localStorage.getItem('accordion-menus') || '{}');
        let name = $(this).attr('for');
        let input = $(this).find('>input[type="checkbox"]');
        let checked = input.is(':checked');
        
        menu[name] = checked;
        localStorage.setItem('accordion-menus', JSON.stringify(menu));
    });

    (function(){
        let menu = JSON.parse(localStorage.getItem('accordion-menus') || '{}')
        $('.accordion-menus > label').each(function(){
            let name = $(this).attr('for');
            let input = $(this).find('>input[type="checkbox"]');
            input.prop('checked', menu[name] || false);
        })
    })()

    $('.accordion-menus').scrollTop(parseInt(localStorage.getItem('accordion-menus-top') || 0));
    $('.accordion-menus').on('scroll', function(){
        let scrollTopValue = $(this).scrollTop();
        localStorage.setItem('accordion-menus-top', scrollTopValue);
    });

    
})