

window.confirm = function(message) {
    
    let e = window.event || window.confirm.caller.arguments[0];
    let el = e.target || e.srcElement;
    let $element = $(el)
    let $dialog = new SimpleAlert();
    const confirmText = $element.data('yes')
    const cancelText = $element.data('no')
    $dialog.confirm(message, confirmText, cancelText, function($yes){
        $yes.click(function(event) {
            event.preventDefault()
            let old = window.confirm
            window.confirm = function() { return true }
            $element.get(0).click()
            window.confirm = old
        })
    })
  
  return false;
}