$(document).on('turbolinks:load', function () {

  const form = $('form')

  if (form.length > 0) {
    form.each(function () {

      if ($(this).data('validate')) {
        const thisForm = this
        
        $(thisForm).on('submit', function (e) {

          const input = $(this).find('input')[0]

          $(input).rules('add', {
            required: true,
            messages: {
              required: $(input).data('required')
            }
          })
        })
        $(thisForm).validate({
          errorPlacement: function(error, element) {
            const errorContainer = element.parent().find('.invalid-feedback')
            errorContainer.html(error)
          },
          highlight: function(element, errorClass, validClass) {
            $(element).addClass('is-invalid')
          },
          unhighlight: function (element, errorClass, validClass) {
            $(element).removeClass('is-invalid')
          }
        })
      }
    })
  }
})