$(document).on('turbolinks:load', function () {
    setDatepicker()
    validateForm()
})

function setDatepicker() {
    const date_picker = $('input.paid-date')
    const time_picker = $('input.paid-time')
    if (date_picker.length > 0 ) {
        date_picker.datepicker({
            dateFormat: "dd MM yy"
        })
    }

    if (time_picker.length > 0) {
        time_picker.timepicker({
            defaultTime: moment().format('LT'),
            minuteStep: 1,
            showMeridian: false,
            icons: {
                up: 'fa fa-chevron-up',
                down: 'fa fa-chevron-down'
            }
        })
    }
}

function validateForm() {
    const form = $('form#confirm-payment-form')


    if (form.length > 0) {
        form.on('submit', function () {
            const paid_date = $('input.paid-date')
            const paid_time = $('input.paid-time')
            const attachment = $('input.attachment')
            const amount = $('input.amount')
            const withholdingTax = $('input.withholding-tax')
            $(paid_date).rules('add', {
                required: true,
                messages: {
                    required: "โปรดระบุวันที่ชำระเงิน"
                }
            })
            $(paid_time).rules('add', {
                required: true,
                messages: {
                    required: "โปรดระบุเวลาที่ชำระเงิน"
                }
            })
            $(attachment).rules('add', {
                required: true,
                messages: {
                    required: "โปรดแนบไฟล์หลักฐานการโอนการชำระเงิน"
                }
            })

            $(amount).rules('add', {
                required: true,
                messages: {
                    required: "โปรดระบุจำนวนที่ชำระ"
                }
            })

            if(withholdingTax.length) {
                $(withholdingTax).rules('add', {
                    required: true,
                    messages: {
                        required: "โปรดแนบไฟล์หลักฐานการโอนการชำระเงิน"
                    }
                })
            }
        })

        form.validate({
            errorPlacement: function(error, element) {
                const errorContainer = element.parent().find('.invalid-feedback')
                errorContainer.html(error)
            },
            highlight: function(element, errorClass, validClass) {
                $(element).addClass('is-invalid')
            },
            unhighlight: function (element, errorClass, validClass) {
                $(element).removeClass('is-invalid')
            }
        })
    }
}