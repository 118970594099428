
class ApiClient {
    constructor(resource) {
        this.resource = resource
    }

    get url() {
        return this.resource ? `${this.resource} ` : ''
    }
}

export default ApiClient