
import Rails from "@rails/ujs";
Rails.delegate_handler = function (elem) {    

    Rails.delegate(elem, Rails.linkDisableSelector, 'ajax:complete', Rails.enableElement);
    Rails.delegate(elem, Rails.linkDisableSelector, 'ajax:stopped', Rails.enableElement);
    Rails.delegate(elem, Rails.buttonDisableSelector, 'ajax:complete', Rails.enableElement);
    Rails.delegate(elem, Rails.buttonDisableSelector, 'ajax:stopped', Rails.enableElement);
    Rails.delegate(elem, Rails.linkClickSelector, 'click', Rails.preventInsignificantClick);
    Rails.delegate(elem, Rails.linkClickSelector, 'click', Rails.handleDisabledElement);
    Rails.delegate(elem, Rails.linkClickSelector, 'click', Rails.handleConfirm);
    Rails.delegate(elem, Rails.linkClickSelector, 'click', Rails.disableElement);
    Rails.delegate(elem, Rails.linkClickSelector, 'click', Rails.handleRemote);
    Rails.delegate(elem, Rails.linkClickSelector, 'click', Rails.handleMethod);
    Rails.delegate(elem, Rails.buttonClickSelector, 'click', Rails.preventInsignificantClick);
    Rails.delegate(elem, Rails.buttonClickSelector, 'click', Rails.handleDisabledElement);
    Rails.delegate(elem, Rails.buttonClickSelector, 'click', Rails.handleConfirm);
    Rails.delegate(elem, Rails.buttonClickSelector, 'click', Rails.disableElement);
    Rails.delegate(elem, Rails.buttonClickSelector, 'click', Rails.handleRemote);
    Rails.delegate(elem, Rails.inputChangeSelector, 'change', Rails.handleDisabledElement);
    Rails.delegate(elem, Rails.inputChangeSelector, 'change', Rails.handleConfirm);
    Rails.delegate(elem, Rails.inputChangeSelector, 'change', Rails.handleRemote);
    Rails.delegate(elem, Rails.formSubmitSelector, 'submit', Rails.handleDisabledElement);
    Rails.delegate(elem, Rails.formSubmitSelector, 'submit', Rails.handleConfirm);
    Rails.delegate(elem, Rails.formSubmitSelector, 'submit', Rails.handleRemote);
    Rails.delegate(elem, Rails.formSubmitSelector, 'submit', function (e) {
      return setTimeout(function () {
        return Rails.disableElement(e);
      }, 13);
    });
    Rails.delegate(elem, Rails.formSubmitSelector, 'ajax:send', Rails.disableElement);
    Rails.delegate(elem, Rails.formSubmitSelector, 'ajax:complete', Rails.enableElement);
    Rails.delegate(elem, Rails.formInputClickSelector, 'click', Rails.preventInsignificantClick);
    Rails.delegate(elem, Rails.formInputClickSelector, 'click', Rails.handleDisabledElement);
    Rails.delegate(elem, Rails.formInputClickSelector, 'click', Rails.handleConfirm);
    Rails.delegate(elem, Rails.formInputClickSelector, 'click', Rails.formSubmitButtonClick);
    elem.addEventListener('DOMContentLoaded', Rails.refreshCSRFTokens);
    elem.addEventListener('DOMContentLoaded', Rails.loadCSPNonce);
    return true;
};