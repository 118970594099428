$(document).on('turbolinks:load', function () {
  const form = $('#update-payment')

  if (form.length > 0 ) {
      const publicKey = "pkey_55e3a52r17y4a4z4302"
      const button = $('#update-payment-submit')
      const amount = $('#monthly-details').data('monthly-price')


      button.click(function (e) {
          e.preventDefault()
          handleOmise()
      })

      function handleOmise() {
          const omise = window.OmiseCard
          omise.configure({
              publicKey: publicKey,
              frameLabel: 'HUBBA',
              image: `${window.location.origin}/HUBBA-Logo-square.jpg`,
          });

          omise.open({
              amount: parseInt(amount)  * 100,
              submitFormTarget: '#update-payment',
              onCreateTokenSuccess: function (nonce) {
                  $('#credit_card_token').val(nonce)
                  form.submit();
                  /* Handler on token or source creation.  Use this to submit form or send ajax request to server */
              }
          })
      }
  }
})