import {getMembershipPrice} from "../services/membership_plans";
import {moneyFormat} from "../utils/money";
import { post } from './api'
import { CORPORATE_WITHOLDING_TAX , getWithPaymentTypeValue, handleQrCode, handleEnableCreditCardMethodForWht, setWithHoldingtax, resetForm } from './checkout.js.erb'
import {isMobileSize} from "../utils/common";
import {getDataRequiredMessage, scrollToForm, showOrHideCorporateInfo} from "./portal_booking.js.erb";


const state = {
    step: 1
}


$(document).on('turbolinks:load', function () {

  const bookingContainer = $('.membership-plan-container')
  const form = bookingContainer.find('form')
  const agreeCheckBox = form.find('#create_membership_agreeTerm')
  const membershipForm = $('#membership-form')

  if( bookingContainer.length <= 0 ) return


  // Add custom rules for validate phone number
  $.validator.addMethod("formatPhone", function(value, element) {
    return new RegExp('^\\(?([0-9]{3})\\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$').test(value)
  }, "Phone is invalid");

  agreeCheckBox.on('change', function () {
    handleChargeButton()
  })
// trigger every input for change order summary
  $('input#membership_start_date').change(function () {
    setOrderSummary()
  })

  
  // handle apply code
  $('button.vocuher-button').click(function (e) {
    e.preventDefault()
    setOrderSummary()

  })

  // handle submit payment form
    $('#submit-omise').click(function (e) {
        e.preventDefault()
        membershipForm.removeData("validator").removeData("unobtrusiveValidation"); //remove the form validation => refresh form.valid()
        $.validator.unobtrusive.parse(membershipForm);//add the form validation
        submitForm()
    })

  // handle remove voucher code
  handleRemoveCode()

  // handle show qr code when selected transfer method
  handleQrCode()

  //handle selected request withholding tax
  handleRequestWithHoldingTax()

  handleBookingForm()

  backStep()
    // set booking order summary (wait 100ms for get booking value)
   function setOrderSummary() {
    setTimeout( function () {

        const membershipId = $('#membership_plan_id').val()
        const startDateInput = $('#membership_start_date').val()
        const membershipDateText = $('#from_to')
        const priceText = $('.price-amount')
        const code = $('#code').length ? $('#code').val().toUpperCase() : ''
        const requestWht = isRequestWht()

        if (membershipId && startDateInput) {
          getMembershipPrice(membershipId, startDateInput, code, requestWht).then(function (data) {

              if (data.order_summary) {


                // Set Data section ====================================
                const orderSummary = data.order_summary

                $('#final_price').attr('data-price', orderSummary.total)

                membershipDateText.html(data.membership_date_text)
                priceText.html(moneyFormat(data.price))

                $('#sub_total').html(moneyFormat(orderSummary.sub_total))
                $('#vat').html(moneyFormat(orderSummary.vat))
                $('#final_price').html(moneyFormat(orderSummary.total))
                hideInvalidCode()
                if(orderSummary.total < 20 ) {
                  $('input#creditcard').prop('disabled', true)
                  $('input#transfer').prop('checked', true)
                }

                if(orderSummary.total === 0) {
                  // If use credit then checkbox request withholding is disabled and unchecked
                    $('#create_membership_requestWht').prop('checked', false)
                    $('#create_membership_requestWht').attr('disabled', true)
                }else {
                  $('#create_membership_requestWht').attr('disabled', false)
                  if(!requestWht) {
                    $('input#creditcard').prop('disabled', false)
                  }
                }
                if(orderSummary.voucher_discount > 0 ) {
                  $('#discount').html(`- ${moneyFormat(orderSummary.voucher_discount)}`)

                showRemoveCodeButton()
                } else {
                  if(orderSummary.voucher_invalid) {
                    setInvalidCode()
                    $('#discount').html(`${moneyFormat(0)}`)
                  } else {
                    $('#discount').html(`${moneyFormat(0)}`)
                  }
                }

                setWithHoldingtax(orderSummary.wht)
                //========================================================

                handleChargeButton()



              } else {
                handleChargeButton()
              }
            })
        } else {
          clearOrderSummary()
        }
    },100)
  }

  // Clear order summary section
  function clearOrderSummary() {
    const hourText = $('#hour-booking')
    const priceText = $('.price-amount')
    $('#sub_total').html(moneyFormat(0))
    $('#vat').html(moneyFormat(0))
    $('#final_price').html(moneyFormat(0))
    hourText.html('')
    priceText.html(moneyFormat(0))
  }



  // handle agree term to disabled or enabled submit button
  function handleChargeButton() {
    const buttonSubmit = $('#submit-omise')
    const agreeCheckBox = form.find('#create_membership_agreeTerm')
    if(isMobileSize() && state.step == 1) return
    if(agreeCheckBox.is(":checked")) {
      buttonSubmit.removeClass('disabled')
      buttonSubmit.removeAttr('disabled')
    } else {
      buttonSubmit.addClass('disabled')
      buttonSubmit.attr('disabled', 'disabled')
    }

  }

  // handle api
  function sendData(data) {
    $('#submit-omise').attr('disabled', true)
    const url = form.attr('action')
    post(url, JSON.stringify(data), true)

  }

  function submitForm() {
      const validateOption = getValidateOption()
      membershipForm.validate({
          errorClass: 'is-invalid',
          rules: validateOption.rules,
          messages: validateOption.messages,
          errorPlacement: function(error, element) {
              const errorContainer = element.parent().find('.invalid-feedback')
              errorContainer.html(error)
          },
          highlight: function(element, errorClass, validClass) {
              $(element).addClass(errorClass)
              scrollToForm()
          },
          unhighlight: function (element, errorClass, validClass) {
              $(element).removeClass(errorClass)
          }
      })

      if(!membershipForm.valid()) return false


      if(isMobileSize() && state.step === 1) {
          state.step = 2
          handleBookingForm()
      } else {
          submitFormData()
      }
  }

  function submitFormData() {
      const paymentMethod = $("input[name='payment-method']:checked").val();
      const code = $('#code').val().toUpperCase()
      const data = {
          firstname: $('#first_name').val(),
          lastname: $('#last_name').val(),
          email: $('#email').val(),
          contact_number: $('#phone').val(),
          noted: $('#note').val(),
          product_type: 'Membership',
          start_date: $('#membership_start_date').val(),
          payment_method: paymentMethod,
          membership_id: $('#membership_plan_id').val(),
          code,
          customer_type: getWithPaymentTypeValue()
      }
      // merge corporate info to data when payment type is corporate

      if(data.customer_type == CORPORATE_WITHOLDING_TAX) {
          Object.assign(data, { company_name: $('#billingName').val(), company_address: $('#billingAddress').val(), company_tax_number: $('#taxId').val(), company_branch: $('#companyBranch').val(), request_for_wht: isRequestWht()})
      }

      if(data.payment_method === "Bank Transfer") {
          //   form.submit()
          sendData({ data })
      }else {

          if('OmiseCard' in window) {
              const publicKey = $('#creditInput').data('omisePublicKey')

              window.OmiseCard.configure({
                  publicKey: publicKey,
                  frameLabel: 'HUBBA',
                  image: `${window.location.origin}/HUBBA-Logo-square.jpg`
              });

              setTimeout(function () {
                  const amount = parseFloat($('#final_price').attr('data-price')) * 100
                  window.OmiseCard.open({
                      amount,
                      submitFormTarget: '#booking-form',
                      onCreateTokenSuccess: function (nonce) {
                          sendData({
                              token: nonce,
                              amount,
                              currency: "thb",
                              data: data
                          })
                          /* Handler on token or source creation.  Use this to submit form or send ajax request to server */
                      }
                  })
              }, 1000)
          }
      }
  }

  handlePaymentType()

  handleEnableCreditCardMethodForWht()

  function handleRemoveCode() {
    $('#remove-code').on('click', function () {
      clearVoucherCode()
      showApplyCodeButton()
    })
  }

  function clearVoucherCode() {
      $('input#code').val('')
  }
  
  function showRemoveCodeButton() {
    $('#apply-code').hide()
    $('#remove-code').show()
  }

  function showApplyCodeButton() {
    $('#apply-code').show()
    $('#remove-code').hide()
  }

  function setInvalidCode() {
    $('#code_error').html('Invalid code').show()
  }

  function hideInvalidCode() {
    $('#code_error').hide()
  }


  // handle select payment type

  function handlePaymentType() {
    showOrHideCorporateInfo()

    $('#customer_type').on('change', function(evt) {

      showOrHideCorporateInfo()
    })
  }

  // handle checkbox request withholding tax

  function handleRequestWithHoldingTax() {
    const requestWhtCheckbox = $('input#create_membership_requestWht')

    if(!requestWhtCheckbox.length) return

    handleEnableCreditCardMethodForWht()

    requestWhtCheckbox.change(function() {
      // set order summary api
      setOrderSummary()
      handleEnableCreditCardMethodForWht()
    })
  }

  function isRequestWht() {
    return $("input#create_membership_requestWht").is(':checked')
  }




})

function getValidateOption() {
    const isValidate = (!isMobileSize() && state.step == 1) || (isMobileSize() && state.step == 2)
    const validateOption = {
        rules: {
            'create_membership[date]': {
                required: true
            },
            'create_membership[type]': {
                required: isValidate
            },
            'create_membership[first_name]': {
                required: isValidate
            },
            'create_membership[last_name]': {
                required: isValidate
            },
            'create_membership[email]': {
                required: isValidate
            },
            'create_membership[phone]': {
                required: isValidate
            },
            'create_membership[billingName]': {
                required: isValidate
            },
            'create_membership[companyBranch]': {
                required: isValidate
            },
            'create_membership[billingAddress]': {
                required: isValidate
            },
            'create_membership[taxId]': {
                required: isValidate
            },

        },
        messages: {
            'create_membership[booking_time]': {
                required: getDataRequiredMessage('booking_time')
            },
            'create_membership[hour]': {
                required: getDataRequiredMessage('hour')
            },
            'create_membership[type]': {
                required: getDataRequiredMessage('customer_type')
            },
            'create_membership[first_name]': {
                required: getDataRequiredMessage('first_name')
            },
            'create_membership[last_name]': {
                required: getDataRequiredMessage('last_name')
            },
            'create_membership[email]': {
                required: getDataRequiredMessage('email')
            },
            'create_membership[phone]': {
                required: getDataRequiredMessage('phone'),
                formatPhone: getDataInvalidMessage('phone')
            },
            'create_membership[billingName]': {
                required: getDataRequiredMessage('billingName')
            },
            'create_membership[companyBranch]': {
                required: getDataRequiredMessage('companyBranch')
            },
            'create_membership[billingAddress]': {
                required: getDataRequiredMessage('billingAddress')
            },
            'create_membership[taxId]': {
                required: getDataRequiredMessage('taxId')
            },
        }
    }
    return validateOption
}



export function getDataInvalidMessage(name) {
  return $(`input[name=${name}]`).data('invalidMessage')
}

function handleBookingForm() {

    if(!isMobileSize()) return

    const bookingForm = $('#meeting-room-form')
    const billingInfoForm = $('#billing-info-form')
    const agreeTerm = $('#agree-term-section');
    const paymentMethod = $('#payment-method-section')
    const submitButton = $('#submit-omise')
    const backButton = $('#back-step')
    const detailSection = $('#detail')

    if(state.step == 1) {
        billingInfoForm.invisible()
        bookingForm.visible()
        agreeTerm.invisible();
        paymentMethod.invisible()
        submitButton.val('Go to Checkout')
        submitButton.attr('disabled', false)
        detailSection.visible()
        backButton.hide()
    } else {
        bookingForm.invisible()
        billingInfoForm.visible()
        paymentMethod.visible()
        agreeTerm.visible();
        submitButton.val('Proceed to check out')
        submitButton.attr('disabled', true)
        detailSection.invisible()
        backButton.show()
        scrollToForm()
    }
}

export function backStep() {
    const backButton = $('#back-step')

    backButton.click(function () {
        state.step = 1
        handleBookingForm()
    })
}
