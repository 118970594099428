import { moneyFormat } from '../utils/money'

export const CREDITCARD = "Omise"
export const CORPORATE_WITHOLDING_TAX = "Corporate"

export function setWithHoldingtax(price) {
  if(price > 0) {
    $('#withholding-tax').text(`- ${moneyFormat(price)}`)
  }else {
    $('#withholding-tax').text(moneyFormat(price))
  }
}


export function resetForm(input, formGroup, action) {
  action = action || ''
  if (input.length > 0) {
    input.removeClass('is-invalid')
    input.removeClass('is-valid')
    const errorMessage = $(formGroup).find('.invalid-feedback')
    errorMessage.html('')
    if(action != 'submit') {
      $('#requestWht').prop('checked', false)
    }

  }
}

// Get value of payment type radio button
export  function getWithPaymentTypeValue() {
  return $('#customer_type').find(":selected").val()
}

//handle payment method radio button show or hide qr image
export function handleQrCode() {
  showOrHideQrImage()

  $("input[name='payment-method']").on('click', function () {
    showOrHideQrImage()
  })
}

// function check for show qr image
export function showOrHideQrImage() {
  const qr = $('#qr-code')

  if(!qr.length) return
  if(getPaymentMethodValue() != CREDITCARD) {
    qr.show()
  }else {
    qr.hide()
  }

}

// get value of payment method
export function getPaymentMethodValue() {
  return $("input[name='payment-method']:checked").val()
}


export function handleEnableCreditCardMethodForWht() {

  const requestWhtCheckbox = $('input#requestWht')
  const paymentMethodRadio = $("input[name='payment-method']")

  if(requestWhtCheckbox.is(':checked')) {

    // Force payment to "Transfer" and disabled "Credit card" option
    setTimeout(function () {
      autoSelectTransferMethod()
    }, 100)

  } else {
      // Allow "Credit card option"
      paymentMethodRadio.attr('disabled', false)
    }



  // Show Qr image if Bank transfer is checked
  setTimeout(function () {
    showOrHideQrImage()
  },100)

}

// auto select "Bank transfer" method

export function autoSelectTransferMethod() {
  $('#transfer').prop('checked', true)
  $('#creditcard').attr('disabled', true)
}
