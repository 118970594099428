
class SimpleModal {
    

    constructor(elem){
        this.modal = elem;
        SimpleModal.modals.push(this)
        this.method1()
    }

    // try method in class
    method1() {
        $('body').addClass('modal-open')
        this.modal.on('click', (event)=>{
            this.close()
        }).find('.modal-inner').on('click', (event)=>{
            event.stopPropagation()
        })
        this.modal.find('.modal-close').on('click', (event)=>{
            this.close()
        })
    }

    open(){
        $('body').append(this.modal)
    }

    close(){
        this.modal.remove()
        $('body').removeClass('modal-open')
    }
}

// modal stack
SimpleModal.modals = []

// modal stack close
$(document).on('keyup', (e) => {
    if($('.simple-modal').length){
        if(e.which == 27) {
            SimpleModal.modals.pop().close()
        }
    }
});

window.SimpleModal = SimpleModal;
