import { getDataRequiredMessage} from "./portal_booking.js.erb";

const PRIVATE_OFFICE = 'Private Office'
$(document).on('turbolinks:load', function () {
  const inquiryContainer = $('#inquiry-form-container')

  if(!inquiryContainer.length) return

  const interestedProductDropdown = inquiryContainer.find('select#inquiry_interested_product')
  const inquiryForm = inquiryContainer.find('form')

  inquiryForm.validate({
    onkeyup: false,
    rules: {
      'inquiry[interested_product]': 'required',
      'inquiry[firstname]': 'required',
      'inquiry[lastname]': 'required',
      'inquiry[email]': 'required',
      'inquiry[phone]': 'required',
      'inquiry[company_name]': 'required',
      'inquiry[prefer_language]': 'required',
      'inquiry[capacity]': 'required',
    },
    messages: {
      'inquiry[interested_product]': {
        required: `${getDataRequiredMessage("'inquiry[interested_product]'")}`
      },
      'inquiry[firstname]': {
        required: `${getDataRequiredMessage("'inquiry[firstname]'")}`
      },
      'inquiry[lastname]': {
        required: `${getDataRequiredMessage("'inquiry[lastname]'")}`
      },
      'inquiry[email]': {
        required: `${getDataRequiredMessage("'inquiry[email]'")}`
      },
      'inquiry[phone]': {
        required: `${getDataRequiredMessage("'inquiry[phone]'")}`
      },
      'inquiry[company_name]': {
        required: `${getDataRequiredMessage("'inquiry[company_name]'")}`
      },
      'inquiry[prefer_language]': {
        required: `${getDataRequiredMessage("'inquiry[prefer_language]'")}`
      },
      'inquiry[capacity]': {
        required: `${getDataRequiredMessage("'inquiry[capacity]'")}`
      },
      'inquiry[budget]': {
        required: `${getDataRequiredMessage("'inquiry[budget]'")}`
      },
      'inquiry[period]': {
        required: `${getDataRequiredMessage("'inquiry[period]'")}`
      }
    },errorPlacement: function(error, element) {
      const errorContainer = element.parent().find('.invalid-feedback')
      errorContainer.html(error)

    },
    highlight: function(element, errorClass, validClass) {
      $(element).addClass('is-invalid')
      if($(element).is("select")) {
        $(element).parent().find('.select2-selection').addClass('is-invalid')
      }
    },
    unhighlight: function (element, errorClass, validClass) {
      $(element).removeClass('is-invalid')
      if($(element).is("select")) {
        $(element).parent().find('.select2-selection').removeClass('is-invalid')
      }
    },
  })



  interestedProductDropdown.change(function () {
    handlePrivateOfficeMoreInfo()
  })
})


function handlePrivateOfficeMoreInfo() {
  const privateOfficeInfo = $('#for_private_office')
  if(getInterestedProductValue() == PRIVATE_OFFICE) {
    privateOfficeInfo.show()
    $('#for_private_office input:not(input[type=checkbox])').each(function () {
      $(this).rules('add', {
        required: true,
      })
    })
  } else {
    privateOfficeInfo.hide()
  }

}

function getInterestedProductValue() {
  return $('select#inquiry_interested_product').val()
}