class Api {
  post = async (url, data = {}, isJson = false) => {
    try {
      const response = await $.ajax({
        method: "POST",
        url: url,
        beforeSend: function (req) {
          const token = $('meta[name=csrf-token]').attr('content');
          req.setRequestHeader('X-CSRF-TOKEN', token)

          if(isJson) {
            req.setRequestHeader('Accept', 'application/json')
            req.setRequestHeader('Content-Type', 'application/json')
          }
        },
        data: data,
      });

      return response
    }catch (e) {
      return { success: false }
    }
  }

  patch = async (url, data = {}) => {
    try {
      const response = await $.ajax({
        method: "PATCH",
        url: url,
        beforeSend: setHeader,
        data: data
      });

      return response
    }catch (e) {
      return { success: false }
    }

  }

  get = async  (url, data = {}) => {
    try{
      const response = await $.ajax({
        method: "GET",
        url: url,
        beforeSend: setHeader,
        data: data
      })

      return response
    }catch (e) {

    }
  }
}


const setHeader = (req) => {
  const token = $('meta[name=csrf-token]').attr('content');
  req.setRequestHeader('X-CSRF-TOKEN', token)
}

export const { post, patch, get } = new Api()