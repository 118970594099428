$(document).on('turbolinks:load', function () {
    const accordionHamburger = $('.accordion-hamburger')
    const accordionMenu = $('.accordion-menus')
    const mainContent = $('.main-content')

    if(!accordionHamburger.length) return

    accordionHamburger.click(function (e) {
        if ($( window ).width() < 1024 && accordionMenu.length){
            e.preventDefault();
            accordionMenu.toggleClass('show')
        }
    })

    
    mainContent.click(function () {
        if(accordionMenu.hasClass('show')) {
            accordionMenu.toggleClass('show')
        }
    })




})