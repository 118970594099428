$(document).on('turbolinks:load', function () {
  const form = $('#founders-form')
  if(form.length > 0) {
  form.on('submit', function (e) {
    const founderFirstname = $('input.founder-firstname')
    const founderLastname = $('input.founder-lastname')
    const founderContact = $('input.founder-contact')
    const founderEmail = $('input.founder-email')
    const founderFacebook = $('input.founder-facebook')
    const founderCompanName = $('input.founder-company-name')
    const founderIndustry = $('select.founder-industry')
    const founderOther = $('input.founder-other')
    founderFirstname.each(function() {
      $(this).rules("add",
        {
          required: true,
          messages: {
            required: "โปรดระบุชื่อ"
          }
        })
    });
    founderLastname.each(function() {
      $(this).rules("add",
          {
            required: true,
            messages: {
              required: "โปรดระบุนามสกุล"
            }
          })
    });
    founderContact.each(function() {
      $(this).rules("add",
        {
          required: true,
          messages: {
            required: "โปรดระบุเบอร์ติดต่อ"
          }
        })
    });
    founderEmail.each(function() {
      $(this).rules("add",
        {
          required: true,
          email: true,
          messages: {
            required: "โปรดระบุอีเมล",
            email: "อีเมลไม่ถูกต้อง"
          }
        })
    });
    founderFacebook.each(function() {
      $(this).rules("add",
        {
          required: true,
          messages: {
            required: "โปรดระบุ Facebook name"
          }
        })
    });

      founderCompanName.each(function() {
          $(this).rules("add",
              {
                  required: true,
                  messages: {
                      required: "โปรดระบุชื่อบริษัท"
                  }
              })
      });

      founderIndustry.each(function() {
          $(this).rules("add",
              {
                  required: true,
                  messages: {
                      required: "โปรดระบุประเภทธุรกิจ"
                  }
              })
      });

      founderOther.each(function(index) {
          const valueIndusrty = $(founderIndustry)[index]
          $(this).rules("add",
              {
                  required: $(valueIndusrty).children("option:selected"). val() === 'Other',
                  messages: {
                      required: "โปรดระบุ"
                  }
              })
      });

  })

//  form.validate().resetForm();
  form.validate({
    errorPlacement: function(error, element) {
      const errorContainer = element.parent().find('.invalid-feedback')
      errorContainer.html(error)
    },
    highlight: function(element, errorClass, validClass) {
      $(element).addClass('is-invalid')
    },
    unhighlight: function (element, errorClass, validClass) {
      $(element).removeClass('is-invalid')
    }
  });
    }
})