import { get } from './api'
import {CORPORATE_WITHOLDING_TAX} from "./checkout.js.erb";
import moment from 'moment'

$(document).on('turbolinks:load', function () {
  const container = $('form#new_purchase #admin-company-info-form')
  const whtInput = $('form#new_purchase #purchase_request_for_wht')
  const taxInput = $('form#new_purchase #purchase_tax_option')
  const customerInput = $('form#new_purchase #purchase_customer_id')
  const customerType = $('form#new_purchase #purchase_customer_type')
  const companyInfoForm = container.find('#company_info')

  if ($('form#new_purchase').length > 0){
    if(whtInput.length > 0) {
      whtInput.change(async function (e) {
        await setOrderSummary(true)
        await handlePaymentType()
        await updateCartInfo()
      })
    }

    if(taxInput.length > 0) {
      taxInput.change(async function (e) {
        await setOrderSummary()
        await handlePaymentType()
        await updateCartInfo()
      })
    }
  
    if(customerType.length) {
      customerType.change(async function () {
        await setCustomerInfo()
        await handlePaymentType()
        await updateCartInfo()
      })
    }
  
    if(customerInput.length > 0) {
       customerInput.change(async function () {
         $('#purchase_company_id').val('').trigger('chosen:updated');
         await setCustomerInfo()
         await setOrderSummary()
         await handlePaymentType()
         await updateCartInfo()
       })
    }
  
    $('body').on('change', '#purchase_company_id', async function () {
      await setCustomerInfo()
    })
  
    async function setOrderSummary(wht_check)  {
      try {
        let requestForWht = whtInput.val()=="1" || whtInput.val()=="2" ;
        let eWht = whtInput.val()=="2"
      
        if(wht_check){
          if(requestForWht){
            customerType.val(CORPORATE_WITHOLDING_TAX)
          }else{
            customerType.val(null)
          }
        }
        const response = await getAdminOrderSummay(customerInput.val(), requestForWht, eWht)
        $('#order-summary-container .card-body .inner').html(response.html)
        $('#credit-container').html(response.credit_html)
      } catch (e) {
        console.log(e)
      }
    }
  
  
    async function setCustomerInfo()  {
      try {
        let requestForWht = whtInput.val()=="1" || whtInput.val()=="2" ;
        let eWht = whtInput.val()=="2"

        if(customerType.val() != CORPORATE_WITHOLDING_TAX && (whtInput.val() == "1" || whtInput.val()=="2")){
          whtInput.val(null)
        }
        const companyId = $('#purchase_company_id').val()
        const response = await getAdminOrderSummay(customerInput.val(), requestForWht, eWht, companyId)
        $('#billing_detail').html(response.billing_html)
        $('#company_detail').html(response.company_html)
        window.formField.searchSelect()
      } catch (e) {
        console.log(e)
      }
    }
  
    const getAdminOrderSummay = async (customer_id, wht, e_wht, company_id) => {

      const tax_option = taxInput.val()
      let key = window.location.pathname.split('/')[2]
      const response = await get(`/admin/${key}/order_summary`, {
        customer_id,
        wht_checked: wht,
        e_wht,
        company_id,
        tax_option
      })
    
      return response
    }
  
    function handlePaymentType() {
      if(customerType.val() === CORPORATE_WITHOLDING_TAX) {
        setTimeout(function () {
          companyInfoForm.show()
        },100)
      } else {
        setTimeout(function () {
          companyInfoForm.hide()
        },100)
      }
    }
  
    handlePaymentType()
  
    async function updateCartInfo() {
      let formData = $('form#new_purchase').serializeArray();
      let key = window.location.pathname.split('/')[2];
      $.ajax({
        url: `/admin/${key}/update_cart_info`,
        method: 'post',
        data: formData
      })
  
      return true
    }
  
    $('form#new_purchase input, form#new_purchase textarea').on('blur', async function(){
      updateCartInfo()
    })
  
    setOrderSummary(false)
  }

})

