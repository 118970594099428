
$(document).on('turbolinks:load', function () {
  const selectInputAntiCafePlan = $('form#new_anti_cafe #anti_cafe_anti_cafe_plan_id')
  const priceInput = $('form#new_anti_cafe input[name="anti_cafe[price]"]')

  if (selectInputAntiCafePlan.length > 0) {
    $('#anti_cafe_anti_cafe_plan_id').on('change', function () {
      if (selectInputAntiCafePlan.val()) {
        setPrice(selectInputAntiCafePlan.val())
      }
    })

    if (selectInputAntiCafePlan.val()) {
      setPrice(selectInputAntiCafePlan.val())
    }
  }

  function setPrice(antiCafePlanId) {
    $.ajax({
      url: `/admin/anti_cafe_plans/get_anti_cafe_order_summary`,
      method: 'post',
      data: {
        anti_cafe_plan_id: antiCafePlanId,
        hours: $('#anti_cafe_hours').val()
      }
    }).done(function (data) {
      priceInput.each(function () {
        $(this).val(data.price)
      })
    })
  }
})
