const MOBILE_WIDTH = 768


export function isMobileSize() {
  const windowSize = $(window).width();
  return windowSize < MOBILE_WIDTH
}


jQuery.fn.invisible = function () {
  return this.css({ visibility: 'hidden', height: 0, minHeight: 0, overflow: 'hidden', margin: 0 })
}

jQuery.fn.visible = function () {
  return this.css({ visibility: 'visible', height: 'auto', minHeight: 'auto', overflow: 'auto' })
}
