import { get } from '../components/api'
import ApiClient from "./api_client";

class PagesAPI extends ApiClient {
    constructor() {
        super();
    }

    getLandMarks(city) {
        return get(`${this.url}/get_landmarks`, { city })
    }

    getMeetingRoom(id, pageType) {
        return get(`${this.url}/get_meeting_room`, { id, type: pageType })
    }

}

export default new PagesAPI();