$(document).on('turbolinks:load', function () {
  var meeting_room = $('#booking_meeting_room_id').val();
  var selected_date = $('#booking_date').val();
  let hour = null
  let start_time = null
  let end_time = null
  disable_end_time()
  run_block_dates()

  function date_equal_today(date){
    const today = moment().startOf('day')
    const compared_date = moment(new Date(date)).startOf('day')
    return compared_date.isSame(today)
  }


  function get_format_time(time){
    return time.split(':')[0] + ':' + (parseInt(time.split(':')[1]) >= 30 ? '30' : '00')
  }

  function run_block_dates(){
    if(!meeting_room && !selected_date) return
    setTimeout(function () {
      $.ajax({
        url: `/admin/meeting_rooms/get_block_dates`,
        method: 'post',
        data: {
          meeting_room_id: meeting_room,
          date: selected_date
        }
      }).done(function ({ time_ranges, block_dates }) {
        set_time_options(time_ranges)
        set_disable_dates(block_dates)
        disabled_all_day(block_dates)
      })

    }, 100)
  }

  function set_time_options(time_ranges) {
    $('#booking_start_time').html(`<option value='' selected disabled>Start time</option>`)
    $('#booking_end_time').html(`<option value='' selected disabled>End time</option>`)

    // Old
    $('#booking_time').html('')

    time_ranges.forEach(function (time) {
      $('#booking_start_time').append(`<option value=${time}>${time}</option>`)
      $('#booking_end_time').append(`<option value=${time}>${time}</option>`)
      // Old
      $('#booking_time').append(`<option value=${time}>${time}</option>`)
    })
  }

  function set_disable_dates(block_dates){
    $('#booking_time').val('')
    $('#booking_start_date').val('')

    // Old
    $('#booking_time option').attr('disabled', false)

    $('#booking_start_time option').attr('disabled', false)
    $('#booking_end_time option').attr('disabled', false)
    if(block_dates[selected_date]){
      block_dates[selected_date].forEach(function(bd){
        $('#booking_time option[value="'+bd+'"]').attr('disabled', true)

        $('#booking_start_time option[value="'+bd+'"]').attr('disabled', true)
        $('#booking_end_time option[value="'+bd+'"]').attr('disabled', true).addClass('block_time')
      })
    }
    
    // TODO: allow for past hour booking
    // if(selected_date && date_equal_today(selected_date)){
    //   var current_time = get_format_time(moment().format("HH:mm"));
    //   $('#booking_start_time option').each(function(){
    //       $(this).attr('disabled', true)
    //       if($(this).val() == current_time){
    //         return false;
    //       }
    //   })
    // }

  }

  $('#booking_meeting_room_id').on('change', function(){
    meeting_room = $(this).val()
    $('#booking_hours').val('')
    run_block_dates()
  })

  $('#booking_date').on('change', async function(){
    selected_date = $(this).val()

    $('input[name="booking[is_all_day]"]').prop('checked', false)
    $('#booking_hours').val('')
    run_block_dates()


  })

  if($('#booking_meeting_room_id').length > 0){
    meeting_room = $('#booking_meeting_room_id').val()
    // run_block_dates()
  }


  $('input[name="booking[is_all_day]"]').on('change', function() {

    if($('#booking_start_time').find('option:not(:disabled)').length == 0) return
    clear_select_time()
    if($(this).val() === 'true') {
      const hours = $('#booking_start_time').find('option:not(:disabled)')
      hour = Math.floor((hours.length - 1) / 2)
      start_time = hours[1].value
      set_hour_input()
      set_time_input()
      trigger_api()
      $('#select-date').hide()
    } else {
      hour = ''
      start_time = ''
      set_hour_input()
      set_time_input()
      $('#select-date').show()
    }
  })
  
  
  $('select#booking_start_time').on('change', function () {
    start_time = $(this).val()
    $('#booking_time').val(start_time)
    enable_end_time()
    disable_end_time_options()
  })


  $('#booking_end_time').on('change', function () {
    end_time = $(this).val()
    calculate_hour()
    trigger_api()
  })


  function disable_end_time() {
    $('#booking_end_time').attr('disabled', true)

  }

  function enable_end_time() {
    $('#booking_end_time').attr('disabled', false)
  }

  function set_hour_input() {
    $('#booking_hours').val(hour)
  }

  function set_time_input() {
    $('#booking_time').val(start_time)
  }


  function disabled_all_day(block_dates) {
    // if(new Date(selected_date).toDateString() === new Date().toDateString()) {
    //   $('input#booking_is_all_day_true').attr('disabled', true)
    //   $('input#booking_is_all_day_false').prop('checked', true)
    //   $('#select-date').show()
    // } else {
    //   if(block_dates[selected_date] != undefined && block_dates[selected_date].length > 0) {
    //     $('input#booking_is_all_day_true').attr('disabled', true)
    //     $('input#booking_is_all_day_false').prop('checked', true)
    //     $('#select-date').show()
    //   } else {
    //     $('input#booking_is_all_day_true').attr('disabled', false)
    //     $('#select-date').hide()
    //   }
    // }
    if(block_dates[selected_date] != undefined && block_dates[selected_date].length > 0) {
      $('input#booking_is_all_day_true').attr('disabled', true)
      $('input#booking_is_all_day_false').prop('checked', true)
      $('#select-date').show()
    } else {
      $('input#booking_is_all_day_true').attr('disabled', false)
      $('#select-date').hide()
    }
  }

  function disable_end_time_options() {
    const startTimeIndex = $('#booking_start_time')[0].selectedIndex

    $('#booking_end_time').val('')
    let index_duplicate = null
    for(let index = 1; index < $(`#booking_end_time option`).length; index++) {
      const element = $(`#booking_end_time option`)[index]

      if($($(`#booking_end_time option`)[index + 3]).hasClass('block_time')) {
        index_duplicate = index + 3
      }

      if(!$(element).hasClass('block_time')) {
        $(element).attr('disabled', false)
        if(((startTimeIndex % 2 == 0 && index % 2 != 0 ) || startTimeIndex >= index ) || ((startTimeIndex % 2 != 0 && index % 2 == 0 ) || startTimeIndex >= index )) {
          $(element).attr('disabled', true)
        }
      }
    }

    if(startTimeIndex < index_duplicate ) {
      for(let index = index_duplicate; index < $(`#booking_end_time option`).length; index++) {
        const element = $(`#booking_end_time option`)[index]
        $(element).attr('disabled', true)
      }
    }

    $(`#booking_end_time option`).each(function(index, element) {

    })
  }

  function calculate_hour() {
    const initial_date = '1/2/33 '
    const start_date = new Date(initial_date + start_time)
    const end_date = new Date(initial_date + end_time)

    hour = (end_date - start_date) / 3600000

    set_hour_input()
  }

  function trigger_api() {
    $("#booking_time").trigger("change");
  }

  function clear_select_time() {
    $('#booking_end_time').val('')
    $('#booking_end_time').attr('disabled', true)
    $('#booking_start_time').val('')
  }
})