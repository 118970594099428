
$(document).on('turbolinks:load', function () {
  
  const selectInputMembershipPlan = $('form#new_membership #membership_membership_plan_id')
  const selectInputMembershipStartDate = $('form#new_membership #membership_start_date')
  const priceInput = $('form#new_membership input[name="membership[price]"]')

  if (selectInputMembershipPlan.length > 0 && selectInputMembershipStartDate.length > 0) {
    $('#membership_membership_plan_id, #membership_start_date').on('change', function () {
      if (selectInputMembershipPlan.val() && selectInputMembershipStartDate.val()) {
        setPrice(selectInputMembershipPlan.val(), selectInputMembershipStartDate.val())
      }
    })
  }

  function setPrice(membershipPlanId, startDate) {
    $.ajax({
      url: `/admin/membership_plans/get_membership_order_summary`,
      method: 'post',
      data: {
        membership_plan_id: membershipPlanId,
        start_date: startDate
      }
    }).done(function (data) {
      priceInput.each(function () {
        $(this).val(data.price)
      })
    })
  }
})
