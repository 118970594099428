import PagesAPI from "../services/pages";
import {isMobileSize} from "../utils/common";

$(document).on('turbolinks:load', function () {
    let step = 1;
  const searchInput = $(`select[name='search[city]']`)

  const cardResult = $('.card-result')

  const slide = $('#slider-full-screen')
  const seeAllImageButton = $('.see_all')
  const cardContainer = $('#selected-result')
  const searchForm = $('#search-form')
  const listCard = $('#list-card')
  const backButton = $('#back-button')
  let meetingRoomId = 0;
  let pageType = 0;

  if (!searchInput.length && !cardResult.length && !slide.length && !seeAllImageButton.length) return

  searchInput.each(function () {
    $(this).on('change', async function () {

      const value = $(this).val()
      const landmarkInput = $(this).closest('form').find(`select[name='search[landmark]']`)
      landmarkInput.empty()

      const { landmarks } = await PagesAPI.getLandMarks(value)

      landmarks.forEach(function (landmark) {
        landmarkInput.append($('<option>').val(landmark).html(landmark))
      })
    })
  })

    if(isMobileSize() && step == 1) {
        cardContainer.hide()

    }

    if(!isMobileSize() || (isMobileSize() && step == 1)) {
        backButton.hide();
    }

    if(!isMobileSize()) {
      cardResult.hover(async function () {
        if(meetingRoomId != $(this).data('id')){
        
          meetingRoomId = $(this).data('id')
          pageType = $(this).data('type')
          
          try {
            const data = await PagesAPI.getMeetingRoom(meetingRoomId, pageType)
            cardContainer.html('')
            if (data.html) {
                step = 2
                cardContainer.html(data.html)
                if(isMobileSize() && step == 1) {
                    cardContainer.hide()
                    backButton.hide();
                }
                if(isMobileSize() && step == 2) {
                    cardContainer.show()
                    listCard.hide();
                    searchForm.hide();
                    backButton.show(); 
                }
            }
            let slick =  cardContainer.find('.slick')
            let numberSlide = slick.data('slideToShow')

            if(slick.length > 0) {
              slick.slick({
                dots: false,
                arrows: true,
                slidesToShow: numberSlide || 1,
                responsive: [{
                  breakpoint: 1700,
                  settings: {
                    slidesToShow: numberSlide || 1,
                    slidesToScroll: numberSlide || 1,
                  },
                },{
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: numberSlide || 1,
                    slidesToScroll: numberSlide || 1,
                  },
                },
                {
                  breakpoint: 420,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  }
                }]
              })
            }
          } catch (error) {
            alert(error.messages)
          }
        }
      })
    }

    $('#back-button a').on('click', function () {
        step = 1
        cardContainer.hide()
        listCard.show();
        searchForm.show();
        backButton.hide();
    })


  const prevArrow = '<i class="fa fa-chevron-circle-left prev" aria-hidden="true"></i>'
  const nextArrow = '<i class="fa fa-chevron-circle-right next" aria-hidden="true"></i>'
  
  $('.slider-single').slick({
    slidesToShow: 1,
    infinite: true,
    prevArrow,
    nextArrow
  });

  $('.slider-nav')
 	.on('init', function(event, slick) {
 		$('.slider-nav .slick-slide.slick-current').addClass('is-active');
 	})
 	.slick({
 		slidesToShow: 7,
 		slidesToScroll: 7,
 		dots: false,
 		focusOnSelect: false,
 		infinite: false,
        prevArrow,
        nextArrow,
        responsive: [{
            breakpoint: 1024,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 5,
            },
        },
        {
            breakpoint: 420,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 5,
            }
 		}]
 	});

 $('.slider-single').on('afterChange', function(event, slick, currentSlide) {
 	$('.slider-nav').slick('slickGoTo', currentSlide);
 	var currrentNavSlideElem = '.slider-nav .slick-slide[data-slick-index="' + currentSlide + '"]';
 	$('.slider-nav .slick-slide.is-active').removeClass('is-active');
 	$(currrentNavSlideElem).addClass('is-active');
 });

 $('.slider-nav').on('click', '.slick-slide', function(event) {
 	event.preventDefault();
 	var goToSingleSlide = $(this).data('slick-index');

 	$('.slider-single').slick('slickGoTo', goToSingleSlide);
 });

  
  seeAllImageButton.on('click', function () {
    slide.css({ visibility: 'initial'})
    $(document).on('keyup', (e) => {
      if(slide.length){
        if(e.which == 27) {
          slide.css({ visibility: 'hidden'})
        }

        if(e.which == 37) {
          $('.prev').click()
        }

        if(e.which == 39) {
          $('.next').click()
        }
      }
    });
    
  })

  $('.close').on('click', function() {
    slide.css({ visibility: 'hidden'})
  })

  
})