
import moment from 'moment'

$(document).on('turbolinks:load', function () {
  const meetingRoomInput = $('form#new_booking #booking_meeting_room_id')
  const hourInput = $('form#new_booking #booking_hours')
  const priceInput = $('form#new_booking input[name="booking[price]"]')
  const dateInput = $('form#new_booking #booking_date')
  const startDateInput = $('form#new_booking #booking_start_date')
  const timeInput = $('form#new_booking #booking_time')

  if (meetingRoomInput.length > 0 && hourInput.length > 0 && priceInput.length > 0) {

    if ($('#page---edit').length > 0) {
      setPrice(meetingRoomInput.val(), hourInput.val(), dateInput.val(), timeInput.val())
    }
    $('#booking_meeting_room_id, #booking_hours, #booking_start_date, #booking_time').on('change', function () {
      if (meetingRoomInput.val() && hourInput.val() && dateInput.val() && timeInput.val()) {
        startDateInput.val(moment(`${dateInput.val()} ${timeInput.val()}`, 'YYYY-MM-DD HH:mm').toDate());
        setPrice(meetingRoomInput.val(), hourInput.val(), dateInput.val(), timeInput.val())
      }
    })
  }

  function setPrice(meetingroomId, hour, start_date, time) {
    $.ajax({
      url: `/admin/meeting_rooms/get_booking_order_summary`,
      method: 'post',
      data: {
        meeting_room_id: meetingroomId,
        hour,
        start_date,
        time
      }
    }).done(function (data) {
      if(data.error) {
        $('#booking_end_time_error').html(data.error)
      } else {
        $('#booking_end_time_error').html('')
        priceInput.each(function () {
          $(this).val(data.price)
        })
        $('#booking_capacity').attr('max', data.capacity)

      }

    })
  }
})

