$(document).on('turbolinks:load', function () {
  countDown()
})

function countDown() {
  $('.counter-time').each( function () {
    const counter = $(this)
    const target = counter.data('target')
    const id = counter.data('id') || ''
    const minute_alert = counter.data('minute-alert') || null
    const hour = $(`#hour${id}`)
    const minutes = $(`#minute${id}`)
    const seconds = $(`#second${id}`)
    $(`#clock${id}`).countdown(target)
      .on('update.countdown', function(event) {
        hour.html(showTwoDigit(event.offset.hours))
        minutes.html(showTwoDigit(event.offset.minutes))
        seconds.html(showTwoDigit(event.offset.seconds))

        if(minute_alert){
          if(event.offset.hours == 0 && event.offset.minutes < minute_alert) {
            counter.addClass('text-danger')
          }
        }
      })
      .on('finish.countdown', function () {
        hour.html('00')
        minutes.html('00')
        seconds.html('00')

        if(minute_alert){
          counter.addClass('text-danger')
        }
      })
  })
}

function showTwoDigit(time) {
  return time < 10 ? '0' + time : time
}