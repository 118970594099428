import flatpickr from 'flatpickr'
window.datetimeInput = {
  datetimeInput: bindDateTimePickers
}

function bindDateTimePickers() {
  [...document.querySelectorAll('[data-type="time"]')].forEach((time) => {
    flatpickr(time, {
      enableTime: true,
      enableSeconds: false,
      noCalendar: true,
      altInput: true,
      altFormat: ' H:i',
      time_24hr: true,
      minuteIncrement: 1
    })
  });

  [...document.querySelectorAll('[data-type="datetime"]')].forEach((time) => {
    flatpickr(time, {
      enableTime: true,
      altInput: true,
      altFormat: 'd/m/Y H:i',
      dateFormat: 'Y-m-d H:i' // Y-m-d H:i
    })
  });

  [...document.querySelectorAll('[data-type="date"]')].forEach((time) => {
    flatpickr(time, {
      minDate: time.getAttribute('min'),
      enableTime: false,
      altInput: true,
      altFormat: 'd/m/Y',
      dateFormat: 'Y-m-d', // Y-m-d H:i,
      disableMobile: "true",
      allowInput: time.getAttribute('required') == 'required'
    })
  })
}

document.addEventListener("turbolinks:load", function () {
  bindDateTimePickers()
})