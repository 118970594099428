import autosize from "autosize/dist/autosize";

$(document).on('turbolinks:load', function () {
  const textarea = $('textarea')
  if(textarea.length > 0) {

    textarea.css('display', 'none')
    autosize(textarea);
    textarea.css('display', '')

    const modal = $('.modal')

    modal.each(function () {
      $(this).on('shown.bs.modal', function () {
        autosize.update(textarea);
      })
    })
  }
})
window.autosize = autosize;